var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "md",
      "id": "modal-new-asset",
      "title": "Tambah Aset Baru"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.isValidForm
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Nama Aset"
    }
  }, [_c('b-form-input', {
    staticClass: "w-full",
    model: {
      value: _vm.form.nama,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nama", $$v);
      },
      expression: "form.nama"
    }
  })], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Aset"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 1,
        text: 'TETAP'
      }, {
        value: 2,
        text: 'BHP'
      }]
    },
    model: {
      value: _vm.form.jenis,
      callback: function ($$v) {
        _vm.$set(_vm.form, "jenis", $$v);
      },
      expression: "form.jenis"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Harga Dasar"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Harga Dasar"
    },
    on: {
      "keyup": _vm.doFormatRupiah
    },
    model: {
      value: _vm.form.harga_dasar,
      callback: function ($$v) {
        _vm.$set(_vm.form, "harga_dasar", $$v);
      },
      expression: "form.harga_dasar"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kategori"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.kategoriOptions,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.id_kategori,
      callback: function ($$v) {
        _vm.$set(_vm.form, "id_kategori", $$v);
      },
      expression: "form.id_kategori"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Satuan"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.satuanOptions,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.id_satuan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "id_satuan", $$v);
      },
      expression: "form.id_satuan"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Volume Aset"
    }
  }, [_c('b-form-input', {
    staticClass: "w-full",
    model: {
      value: _vm.form.volume,
      callback: function ($$v) {
        _vm.$set(_vm.form, "volume", $$v);
      },
      expression: "form.volume"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Aset"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Ex: ",
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tanggal", $$v);
      },
      expression: "form.tanggal"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }