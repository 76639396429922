var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_vm.canPay ? _c('div', {
    staticClass: "d-flex justify-content-end mb-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.print($event);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PrinterIcon"
    }
  }), _vm._v(" Cetak Bukti Procurement")], 1)], 1) : _vm._e(), _c('header', {
    staticClass: "mb-2"
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('section', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('p', [_c('strong', [_vm._v("No. Procurement")])]), _c('p', [_vm._v(_vm._s(_vm.pengadaan.no))])]), _c('div', [_vm.canManage && _vm.pengadaan.status == 5 && _vm.pengadaan.selesai == 0 ? _c('b-button', {
    attrs: {
      "disabled": !_vm.thereRincian,
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push(("/asset-penyimpanan/add?pg=" + (_vm.pengadaan.id)));
      }
    }
  }, [_c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Tempatkan Aset")]), _c('feather-icon', {
    staticClass: "ml-1",
    attrs: {
      "icon": "ChevronRightIcon"
    }
  })], 1) : _vm._e(), _vm.canApprove && [0, 2].includes(_vm.pengadaan.status) ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "info"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.approve('approve');
      }
    }
  }, [_vm._v(" Setujui Procurement ")]) : _vm._e(), _vm.canApprove && _vm.pengadaan.status == 1 ? _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "size": "sm",
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.approve('cancel');
      }
    }
  }, [_vm._v(" Batalkan Procurement ")]) : _vm._e(), _vm.canPay && _vm.pengadaan.status == 1 ? _c('b-button', {
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.showModalKas(1);
      }
    }
  }, [_vm._v(" Pilih Sumber Pengeluaran ")]) : _vm._e(), _vm.canPay && _vm.pengadaan.status == 3 ? _c('b-button', {
    attrs: {
      "variant": "outline-danger",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.cancelKas($event);
      }
    }
  }, [_vm._v(" Batalkan Pemilihan Kas ")]) : _vm._e(), _vm.canManage && _vm.pengadaan.status == 3 ? _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "outline-success",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.approve('approve');
      }
    }
  }, [_vm._v(" Terima Procurement ")]) : _vm._e()], 1)])]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal Procurement")])]), _c('p', {
    class: !_vm.isAdminAsset ? 'mb-2' : ''
  }, [_vm._v(_vm._s(_vm.pengadaan.tanggal))]), !_vm.isAdminAsset ? _c('p', [_c('strong', [_vm._v("Info Gudang Aset")])]) : _vm._e(), !_vm.isAdminAsset ? _c('p', [_vm._v(_vm._s(_vm.gudang ? _vm.gudang.nama_gudang : ''))]) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Info Penempatan")])]), _vm.pengadaan.selesai == 1 ? _c('p', [_c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Selesai")])], 1) : _vm._e(), _vm.pengadaan.selesai == 0 ? _c('p', [_vm._v("Belum ditempatkan")]) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Keterangan")])]), _c('p', {
    staticClass: "mb-1"
  }, [_vm._v(_vm._s(_vm.pengadaan.keterangan ? _vm.pengadaan.keterangan : 'kosong'))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-info"
    }
  }, [_vm._v(_vm._s(_vm.pengadaan.status_ket ? _vm.capitalize(_vm.pengadaan.status_ket) : 'Menunggu persetujuan Owner'))])], 1)], 1)], 1)], 1), _c('main', [_c('b-card', [_c('h3', [_vm._v("Daftar Aset")]), _vm.allowUpdate() && _vm.pengadaan.status == 0 ? _c('b-button', {
    staticClass: "mb-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addAsset($event);
      }
    }
  }, [_vm._v("Pilih Aset")]) : _vm._e(), _c('b-table', {
    staticClass: "mb-2",
    attrs: {
      "striped": "",
      "responsive": "",
      "hover": "",
      "items": _vm.rincians,
      "fields": _vm.rincianFields
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref) {
        var item = _ref.item;
        return [item.asset ? _c('span', [_vm._v(" " + _vm._s(item.asset.nama) + " ")]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Aset tidak ditemukan")])];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" Rp " + _vm._s(item.asset ? _vm.formatRupiah(item.asset.harga_dasar) : 0) + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.asset && item.asset.satuan ? item.asset.satuan.satuan : '-') + " ")];
      }
    }, {
      key: "cell(harga)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(parseInt(item.harga))) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.status == 0 || item.status == 2 ? _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "XIcon",
            "size": "24"
          }
        }) : _c('feather-icon', {
          staticClass: "text-info",
          attrs: {
            "icon": "CheckIcon",
            "size": "24"
          }
        })];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref6) {
        var index = _ref6.index;
        return [_vm.allowDelete() && _vm.pengadaan.status == 0 ? _c('feather-icon', {
          staticClass: "cursor-pointer text-danger",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(index);
            }
          }
        }) : _vm._e()];
      }
    }])
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('h4', {
    staticClass: "mb-4"
  }, [_vm._v("Total: Rp " + _vm._s(_vm.formatRupiah(_vm.totalRincian)))])]), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.allowUpdate() && _vm.pengadaan.status == 0 ? _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.totalRincian < 1
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan")]) : _vm._e()], 1)], 1)], 1), _c('asset-modal', {
    attrs: {
      "assets": _vm.assets
    },
    on: {
      "submit": _vm.onChooseAsset
    }
  }), _vm.canPay ? _c('kas-modal', {
    on: {
      "submitKas": _vm.submitKas
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }