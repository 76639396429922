<template>
    <section>
        <b-modal no-close-on-backdrop no-close-on-esc size="xl" id="modal-asset" title="Daftar Aset">
            <b-table
                responsive
                bordered
                :fields="fields"
                :items="localAssets"
                class="mb-2"
            >
                <template #head(checkbox)>
                    <b-form-checkbox v-model="allChecked" />
                </template>
                <template #cell(checkbox)="{item}">
                    <b-form-checkbox v-if="parseInt(item.harga_dasar) > 0" v-model="selectedAssets" :value="item" />
                    <feather-icon icon="XIcon" class="text-danger" v-else></feather-icon>
                </template>
                <template #cell(satuan)="{item}">
                    {{ item.satuan ? item.satuan.satuan : '' }}
                </template>
                <template #cell(harga_dasar)="{item}">
                    Rp {{ formatRupiah(item.harga_dasar) }}
                </template>
                <template #cell(jumlah)="{item}">
                    <b-form-input v-if="parseInt(item.harga_dasar) > 0" v-model="item.jumlah"></b-form-input>
                    <i class="text-danger" v-else><small>Harga dasar 0</small></i>
                </template>
            </b-table>
            <b-button size="sm" @click.prevent="addNewAsset">
                <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>Aset Baru
            </b-button>
            <template #modal-footer>
                <b-button :disabled="!thereSelectedAsset" @click.prevent="submit" variant="primary">Pilih Aset</b-button>
            </template>
        </b-modal>
        <new-asset-modal @afterSubmit="updateLocalAset"></new-asset-modal>
    </section>
</template>
<script>
import {BTable, BButton, BFormCheckbox, BFormInput} from 'bootstrap-vue'
import NewAssetModal from './ModalNewAsset.vue'
export default {
    props: ['assets'],
    components: {
        BTable, BButton, BFormCheckbox, BFormInput, NewAssetModal
    },
    computed: {
        thereSelectedAsset() {
            return this.selectedAssets.length > 0
        }
    },
    watch: {
        allChecked(val) {
            if(val) {
                this.selectedAssets = this.localAssets.filter(item => parseInt(item.harga_dasar) > 0)
            }
            else {
                this.selectedAssets = []
            }
        },
        assets(val) {
            this.localAssets = val
            this.localAssets.map(asset => {
                asset.jumlah = 0
            })
        }
    },
    data: () => ({
        localAssets: [],
        selectedAssets: [],
        allChecked: false,
        fields: [
            {key: 'checkbox', label: '#'},
            {key: 'nama', label: 'Nama Aset'},
            {key: 'kode', label: 'Kode'},
            {key: 'volume', label: 'Volume'},
            {key: 'satuan', label: 'Satuan'},
            {key: 'harga_dasar', label: 'Harga Dasar'},
            {key: 'jumlah', label: 'Jumlah'}
        ]
    }),
    methods: {
        updateLocalAset(aset) {
            this.localAssets.push(aset)
            this.$bvModal.hide('modal-new-asset')
        },
        addNewAsset() {
            this.$bvModal.show('modal-new-asset')
        },
        submit() {
            if(this.selectedAssets.length < 1) {
                this.displayError({
                    message: 'Harap pilih minimal 1 asset!'
                })
                return false
            }

            // validation item 0
            const there0Jumlah = this.selectedAssets.some(item => item.jumlah < 1)
            if(there0Jumlah) {
                this.displayError({
                    message: 'Harap isi jumlah aset minimal 1!'
                })
                return false
            }
            let result = Object.assign([], this.selectedAssets)
            const payloads = []
            result.map(asset => {
                payloads.push({
                    pengadaan_id: this.$route.params.id,
                    asset_id: asset.id,
                    id_satuan: asset.satuan ? asset.satuan.id : null,
                    satuan: asset.satuan,
                    harga: parseInt(asset.jumlah) * parseInt(asset.harga_dasar),
                    harga_dasar: parseInt(asset.harga_dasar),
                    jumlah: parseInt(asset.jumlah),
                    status: 0,
                    asset
                })
            })
            this.$emit('submit', payloads)
        }
    }
}
</script>